a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.mb-3, .my-3 {
    margin-bottom: 0rem !important;
}

.bg-dark {
    background-color: #404F69 !important;
}

.dx-icon:first-child > .dx-icon-menu {
  color: white;
}


